// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgGroup = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 12.75A6.25 6.25 0 0 0 1.75 19v1a.75.75 0 0 1-1.5 0v-1a7.75 7.75 0 0 1 15.5 0v1a.75.75 0 0 1-1.5 0v-1A6.25 6.25 0 0 0 8 12.75"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 9.75A4.25 4.25 0 0 0 13.75 14h-1.5a5.75 5.75 0 0 1 11.5 0v.5a.75.75 0 0 1-1.5 0V14A4.25 4.25 0 0 0 18 9.75M8 4.75a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5M3.25 8a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M14.25 6a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0"
      clipRule="evenodd"
    />
  </svg>
);
export const GroupIcon = forwardRef(SvgGroup);
